jQuery(document).ready( function($) {


	//for building pages
	var $grid = $('.grid').imagesLoaded( function() {
	  // init Isotope after all images have loaded
	  $grid.isotope({
	      itemSelector: '.grid-item',
		  percentPosition: true,
		  masonry: {
		    columnWidth: '.grid-sizer'
		  }
	  });
	});


	//share icons copy to clipboard

	function copyToClipboard(element) {
	    var $temp = $("<input>");
	    $("body").append($temp);
	    $temp.val($(element).text()).select();
	    document.execCommand("copy");
	    $temp.remove();
	}


	//copy link to clipboard
	$('.clipboard').on('click', function(event) {
		event.preventDefault();
		var $element = $(this).find('.url-text');
		copyToClipboard($element);
		$(this).addClass('copied');
	})


});
