jQuery(document).ready(function( $ ) {

	$('.image-slider').flickity({
	  // options
		freeScroll: true,
		freeScrollFriction: 0.03, // lower friction, slides easier
		wrapAround: true,
		//contain: true,
		prevNextButtons: true,
		pageDots: false,
		cellAlign: 'left',
		setGallerySize: false,
		resize: true,
		imagesloaded: true
	});

});
