jQuery(document).ready(function($) {
	

	/*

	/// this is for making the parallax-style footer reveal
	function siteFooter() {

		var siteFooterID = $('#footer-reveal');
		var siteFooterHeight = siteFooterID.height();
		//find sibling of footer to add padding below
		var siteContent = siteFooterID.prev();
		var siteContentHeight = siteContent.height();

		
		console.log('Content Height = ' + siteContentHeight + 'px');
		console.log('Footer Height = ' + siteFooterHeight + 'px');
 		
	siteContent.css({
			"padding-bottom" : siteFooterHeight + 50
		});
	}



		// INITIATE THE FOOTER
  	siteFooter();
	

	$(window).resize(function() {
		siteFooter();
	});
	
*/


});