jQuery(document).ready( function($) {

	var $seasonslider = $('.season-illustrations').flickity({
		wrapAround: true,
		prevNextButtons: false,
		pageDots: false,
		cellAlign: 'center',
		hash:true,
		imagesloaded: true,
		resize: true
	 
	});

	var $activeslideIndex = $('.season-slide.is-selected').index('.season-slide');
	var $activeNav = $('.season-nav .season-nav--item')[$activeslideIndex];
	$($activeNav).addClass('active');
		
	  

	//set the current description for the first season slider
	 var $currDesc = $('.season-desc .season-desc--item.active');
	 $($currDesc).parent().css("height",$($currDesc).height());



	$seasonslider.on( 'change.flickity', function( event, index ) {
	  //console.log( 'Slide changed to ' + index );
	  //var $activeslideID = $('.season-slide')[index].id;

	  $('.season-nav .season-nav--item').removeClass('active');
	  $('.season-desc .season-desc--item').removeClass('active');

	  var $linkActivated = $('.season-nav .season-nav--item')[index];
	  var $descActivated = $('.season-desc .season-desc--item')[index];
	  $($linkActivated).addClass('active');
	  $($descActivated).addClass('active');
	  $($descActivated).parent().css("height",$($descActivated).height());
	});


	

});
